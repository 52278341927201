import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Badge } from '../atoms'

export default class ParticipanStatus extends Component {
  static propTypes = {
    status: PropTypes.string
  }

  render () {
    const label = this._getLabel()
    const intent = this._getIntent()
    return (
      <Badge intent={intent}>
        {label}
      </Badge>
    )
  }

  _getLabel = () => {
    const options = {
      pending: 'Pendente',
      valid: 'Válido',
      invalid: 'Inválido',
    }
    const { status } = this.props
    return options[status]
  }

  _getIntent = () => {
    const options = {
      pending: 'pending',
      valid: 'success',
      invalid: 'danger',
    }
    const { status } = this.props
    return options[status]
  }
}
