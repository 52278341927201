import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'participants'

export default {
  create(data) {
    return v8n()
      .schema({
        username: v8n().not.null().string().minLength(2),
        text: v8n().not.null().string().minLength(2)
      })
      .testAsync(data)
      .then(() => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },
}
