import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from '.'

const identifier = 'generic'

const _setListFilters = (data, name) => ({ type: 'SET_GENERIC_LIST_FILTERS', data, name })
const _setList = (data, name) => ({ type: 'SET_GENERIC_LIST', data, name })
const _setItem = (data, name) => ({ type: 'SET_GENERIC_ITEM', data, name })
const _updateItem = (data, name) => ({ type: 'UPDATE_GENERIC_ITEM', data, name })
const _sortList = (data, name) => ({ type: 'SORT_GENERIC_LIST', data, name })
const _removeItem = (data, name) => ({ type: 'REMOVE_GENERIC_LIST_ITEM', data, name })

export const loadList = (item, token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setListFilters(filters, item.name))
    }
    dispatch(beforeSend(identifier, { name: item.name }))
    const query = { ...filters, ...(item.listExtraParams || {}) }
    return api.get(`admin/${item.endpoint}`, query, token)
      .then(result => {
        if (result.success) {
          dispatch(_setList(result.data, item.name))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const loadItem = (item, token, id) => {
  return dispatch => {
    dispatch(_setItem(null, item.name))
    dispatch(beforeSend(identifier, { name: item.name }))
    const query = { ...(item.showExtraParams || {}) }
    return api.get(`admin/${item.endpoint}/${id}`, query, token)
      .then(result => {
        if (result.success) {
          dispatch(_setItem(result.data, item.name))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const createItem = (item, token, data, options = {}) => {
  return dispatch => {
    dispatch(beforeSend(identifier, { name: item.name }))
    return api.post(`admin/${item.endpoint}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          if (!options.noRedirect) {
            dispatch(setRedirect({ screen: `${item.name}List` }))
          }
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const updateItem = (item, token, data, options = {}) => {
  return dispatch => {
    dispatch(beforeSend(identifier, { name: item.name }))
    return api.put(`admin/${item.endpoint}/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateItem(result.data))
          dispatch(notificate(result.message, 'info'))
          if (!options.noRedirect) {
            dispatch(setRedirect({ screen: `${item.name}List` }))
          }
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const destroyItem = (item, token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier, { name: item.name }))
    return api.delete(`admin/${item.endpoint}/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_removeItem(id, item.name))
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const sortList = (item, token, data) => {
  return dispatch => {
    dispatch(_sortList(data, item.name))
    dispatch(beforeSend(identifier, { name: item.name }))
    return api.put(`admin/${item.endpoint}/sort`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}

export const exportData = (item, token, filters = {}) => {
  return dispatch => {
    dispatch(beforeSend(identifier, { name: item.name }))
    return api.request(
      'get',
      `admin/${item.endpoint}/export-data`,
      filters,
      token,
      {
        responseType: 'blob',
      }
    )
      .then(result => {
        dispatch(afterResponse(identifier, { name: item.name }))
        return result
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier, { name: item.name }))
      })
  }
}
