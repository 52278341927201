import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../atoms'
import { Spinner } from '../molecules'
import { Confirmation, DataTable, ListFilters } from '../organisms'
import { loadList, exportData, createItem, destroyItem } from '../../store/actions/generic'
import { date, downloadFile } from '../../utils'
import ParticipanStatus from '../molecules/ParticipantStatus'
import ParticipantForm from '../organisms/ParticipantForm'

class Participants extends Component {
  state = {
    filters: {
      page: 1,
      limit: 50
    },
    currentId: null
  }

  componentDidMount() {
    this._loadData()
  }

  render() {
    const { currentId } = this.state
    const { list, paging, sending, filters } = this.props.generic.modules.participants
    const columns = this._getColumns()
    const itemFilters = this._getItemFilters()
    const filterOptions = this._getFilterOptions()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Button size="small" disabled={sending} onClick={this._exportData}>
            Exportar Participantes Válidos
          </Button>
        </div>
        <div className="filter-form">
          <ListFilters
            onChange={this._onFilter}
            data={filters}
            filters={itemFilters}
            filterOptions={filterOptions}
          />
          <ParticipantForm onSubmit={this._onCreate} notificate={this._onNotificate} />
        </div>
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onChangePage={this._onChangePage}
          onDelete={this._onDelete}
        />
      </div>
    )
  }

  _loadData = () => {
    const item = {
      name: 'participants',
      endpoint: 'participants',
    }
    const { token } = this.props.admin.admin
    const { filters } = this.state
    this.props.loadList(item, token, filters)
  }

  _getColumns = () => {
    return [
      {
        name: 'Código',
        field: 'code'
      },
      {
        name: 'Nome de usuário',
        field: 'username'
      },
      {
        name: 'Nome do amigo',
        field: 'friend'
      },
      {
        name: 'Comentário',
        field: 'comment',
        filter: (value) => {
          return (
            <div style={{ padding: 10, backgroundColor: '#ddd', maxWidth: 300 }}>{value}</div>
          )
        }
      },
      {
        name: 'Data',
        field: 'timestamp',
        filter: (value) => date.formatDatetime(new Date(value)),
      },
      {
        name: 'Status',
        field: 'status',
        filter: (value) => <ParticipanStatus status={value} />
      },
      {
        name: 'Erro',
        field: 'status_errors',
        filter: (value) => {
          const items = (value || '').split(',')
          return (
            <ul>
              {items.map((item, index) => {
                return (
                  <li key={index}>{item}</li>
                )
              })}
            </ul>
          )
        }
      },
    ]
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    return list
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _getItemFilters = () => {
    return [
      'search',
      'status',
    ]
  }

  _getFilterOptions = () => {
    return {
      status: {
        options: [
          {
            label: 'Todos',
            value: '',
          },
          {
            label: 'Pendente',
            value: 'pending',
          },
          {
            label: 'Válido',
            value: 'valid',
          },
          {
            label: 'Inválido',
            value: 'invalid',
          },
        ]
      }
    }
  }

  _getItem = () => ({ name: 'participants', endpoint: 'participants' })

  _exportData = async () => {
    const { token } = this.props.admin.admin
    const result = await this.props.exportData(this._getItem(), token)
    downloadFile({
      name: 'participants.csv',
      type: result.type,
      data: result,
    })
  }

  _onNotificate = async (data) => {
    console.log('_onNotificate', data)
  }

  _onCreate = async (data, form) => {
    const { token } = this.props.admin.admin
    const result = await this.props.createItem(this._getItem(), token, data)
    if (result.success) form.resetForm()
    this._reloadData()
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = async () => {
    const { currentId } = this.state
    const { token } = this.props.admin.admin
    this.setState({ currentId: null }, () => {
      this.props.destroyItem(this._getItem(), token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = async () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }
}

const mapStateToProps = ({ admin, generic }) => ({
  admin,
  generic
})

const mapDispatchToProps = {
  loadList,
  exportData,
  createItem,
  destroyItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Participants)
