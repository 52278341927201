export default {
  header: {
    controlPanel: 'Painel administrativo',
  },
  titles: {
    login: 'Login',
  },
  admin: {
    email: 'E-mail',
    password: 'Senha',
  },
  actions: {
    enter: 'Entrar',
  },
  validations: {
    admins: {
      email: {
        validEmail: 'E-mail inválido',
      },
      password: {
        string: 'Digite sua senha',
        minLength: 'Sua senha precisa ter ao menos 6 caracteres',
      },
    },
    projectCategories: {
      name: {
        minLength: 'Digite o nome',
        maxLength: 'Máximo 255 caracteres',
      },
      slug: {
        minLength: 'Slug inválido',
        maxLength: 'Máximo 255 caracteres',
      },
    },
    teamMembers: {
      upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      name: {
        minLength: 'Digite o nome',
        maxLength: 'Máximo 255 caracteres',
      },
      role: {
        minLength: 'Digite o cargo',
        maxLength: 'Máximo 255 caracteres',
      },
      sort: {
        number: 'Digite um número positivo',
        positive: 'Digite um número positivo',
      },
    },
    partners: {
      logo_upload_id: {
        number: 'Escolha um logo',
        positive: 'Escolha um logo',
      },
      image_upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      name: {
        minLength: 'Digite o nome',
        maxLength: 'Máximo 255 caracteres',
      },
      client_name: {
        minLength: 'Digite o nome do funcionário',
        maxLength: 'Máximo 255 caracteres',
      },
      client_role: {
        minLength: 'Digite o cargo do funcionário',
        maxLength: 'Máximo 255 caracteres',
      },
      testimonial: {
        minLength: 'Digite o testemunho do funcionário',
        maxLength: 'Máximo 255 caracteres',
      },
      sort: {
        number: 'Digite um número positivo',
        positive: 'Digite um número positivo',
      },
    },
    projects: {
      home_upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      project_category_id: {
        number: 'Escolha uma categoria',
        positive: 'Escolha uma categoria',
      },
      mobile_upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      desktop_upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      header_upload_id: {
        number: 'Escolha uma imagem',
        positive: 'Escolha uma imagem',
      },
      list_type: {
        number: 'Tipo da lista inválido',
        positive: 'Tipo da lista inválido',
      },
      client: {
        minLength: 'Digite o nome do cliente',
        maxLength: 'Máximo 255 caracteres',
      },
      title: {
        minLength: 'Digite o título',
        maxLength: 'Máximo 255 caracteres',
      },
      slug: {
        slug: 'Slug inválido',
        minLength: 'Slug inválido',
        maxLength: 'Slug inválido',
      },
      description: {
        minLength: 'Digite a descrição',
        maxLength: 'Máximo 255 caracteres',
      },
      sort: {
        number: 'Digite um número positivo',
        positive: 'Digite um número positivo',
      },
      project_media: {
        array: 'Envie ao menos uma imagem para galeria',
      },
    },
    participants: {
      username: {
        minLength: 'Digite o nome do usuário',
        string: 'Digite o nome do usuário',
      },
      text: {
        minLength: 'Digite o comentário',
        string: 'Digite o comentário',
      },
    },
  },
  date: {
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNames: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    amPm: ['am', 'pm'],
  },
}
