import { adminValidator as validator } from '../validators'

const fields = []

const filters = ['search']

const beforeSubmit = async function (data, method) {
  return data
}

const formatData = (data) => {
  return {
    ...data,
  }
}

export default {
  singular: 'Participante',
  plural: 'Participantes',
  endpoint: 'participants',
  name: 'participants',
  slug: 'participantes',
  sortable: false,
  sortableField: null,
  listColumns: [
    'id',
  ],
  filters,
  fields,
  validator,
  beforeSubmit,
  formatData
}
