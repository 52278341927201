import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { participantValidator } from '../../validators'
import { form } from '../../utils'

export default class ParticipantForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      comment_id: '',
      username: '',
      text: '',
    },
    errors: {}
  }

  fields = [{
    label: 'ID do comentário (opcional)',
    field: 'comment_id',
    type: 'text',
  }, {
    label: 'Username',
    field: 'username',
    type: 'text',
    props: (props) => ({
      ...props,
      helpText: 'Digite o nome do usuário sem o @'
    }),
  }, {
    label: 'Comentário',
    field: 'text',
    type: 'text',
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form form--card" onSubmit={this._handleSubmit}>
        <h2 className="form__title">Adicionar participante:</h2>
        <div className="form__row">
          {this._getFields()}
          <Button
            type='submit'
            size='small'
            spinner={sending}
            disabled={sending}
          >Salvar</Button>
        </div>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      const value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props({ props: this.props, model })
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          value={value}
          onChange={this._onChange(item.field)}
          errors={form.getErrors(item.field, errors)}
        />
      )
    })
  }

  resetForm () {
    const model = {
      comment_id: '',
      username: '',
      text: '',
    }
    this.setState({ model })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const { model } = this.state
    const data = { ...model }
    const method = !this.props.data ? 'create' : 'edit'
    participantValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data, this)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const model = {
        ...this.state.model,
        [field]: value
      }
      this.setState({ model })
    }
  }
}
